const BASE_URL = 'https://storage.googleapis.com/tijanisidiali/assets/img/';

const BPM = `${BASE_URL}BPM.png`;
const Budget = `${BASE_URL}budget.png`;
const Camera = `${BASE_URL}caméra.jpeg`;
const CNRS = `${BASE_URL}cnrs.png`;
const DeepLearning = `${BASE_URL}deepoeil.png`;
const Profile = `${BASE_URL}profile_picture.png`;
const Somelec = `${BASE_URL}Somelec.png`;
const Tetris = `${BASE_URL}tetris_3D.png`;
const Todo = `${BASE_URL}todo.png`;
const ENSTA = `${BASE_URL}ENSTA_Paris.png`;
const ENIT = `${BASE_URL}enit.png`;
const IPparis = `${BASE_URL}ipparis.png`;
const Replay = `${BASE_URL}replay.svg`;
const SoundOff = `${BASE_URL}soundOff.svg`;
const SoundOn = `${BASE_URL}soundOn.svg`;
const Levi = `${BASE_URL}levi.png`;
const ErenThumb = `${BASE_URL}erenThumb.png`;
const Kuruko = `${BASE_URL}kurukoImg.png`;
const KurukoThumb = `${BASE_URL}kurukoThumb.png`;
const Goku = `${BASE_URL}gokuImg.png`;
const DragonBallThumb = `${BASE_URL}dragonballThumb.png`;
const Gon = `${BASE_URL}gonImg.png`;
const GonThumb = `${BASE_URL}gonThumb.png`;
const LeagueOfLegend = `${BASE_URL}lol.png`;
const WalkingDead = `${BASE_URL}walking-dead.jpg`;
const LifeIsStrange = `${BASE_URL}life-is-strange.png`;
const WorldOfWarcraft = `${BASE_URL}world-of-warcraft.jpg`;
const HotlineMiami = `${BASE_URL}hotline-miami.jpeg`;
const TVShowLogo = `${BASE_URL}tvShowLogo.svg`;
const ChevronLeft = `${BASE_URL}chevronLeft.svg`;
const ChevronRight = `${BASE_URL}chevronRight.svg`;
const Contact = `${BASE_URL}contact.svg`;
const Controller = `${BASE_URL}controller.svg`;
const Cross = `${BASE_URL}cross.svg`;
const Linkedin = `${BASE_URL}linkedin.svg`;
const Next = `${BASE_URL}next.svg`;
const Pause = `${BASE_URL}pause.svg`;
const Play = `${BASE_URL}play.svg`;
const Previous = `${BASE_URL}previous.svg`;
const Spotify = `${BASE_URL}spotify.svg`;
const TVShows = `${BASE_URL}tvShow.svg`;
const Github = `${BASE_URL}github.svg`;
const ProfilePicture = `${BASE_URL}profile_picture.png`;
const Personal = `${BASE_URL}react.svg`;
const Python = `${BASE_URL}python.svg`;
const Languges = `${BASE_URL}languages.svg`;
const IA = `${BASE_URL}ai.svg`;
const Database = `${BASE_URL}database.svg`;
const Devop = `${BASE_URL}devops.svg`;

export {
  BPM,
  IA,
  Database,
  Devop,
  Languges,
  Python,
  IPparis,
  Budget,
  Camera,
  Personal,
  CNRS,
  DeepLearning,
  Profile,
  Somelec,
  Tetris,
  Todo,
  ENSTA,
  ENIT,
  Replay,
  SoundOff,
  SoundOn,
  Levi,
  ErenThumb,
  Kuruko,
  KurukoThumb,
  Goku,
  DragonBallThumb,
  Gon,
  GonThumb,
  LeagueOfLegend,
  WalkingDead,
  LifeIsStrange,
  WorldOfWarcraft,
  HotlineMiami,
  TVShowLogo,
  ChevronLeft,
  ChevronRight,
  Contact,
  Controller,
  Cross,
  Linkedin,
  Next,
  Pause,
  Play,
  Previous,
  Spotify,
  TVShows,
  Github,
  ProfilePicture
};
