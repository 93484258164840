const BASE_URL = 'https://storage.googleapis.com/tijanisidiali/assets/img/tvshows/';

const RiseOfTheCobra = `${BASE_URL}Rise%20of%20the%20cobra.jpeg`;
const Hitman = `${BASE_URL}Hitman.jpeg`;
const BreakingBad = `${BASE_URL}breakingBad.jpg`;
const Got = `${BASE_URL}got.jpg`;
const Equalizer = `${BASE_URL}Equalizer.jpeg`;
const Taken = `${BASE_URL}taken.jpeg`;

export {
  RiseOfTheCobra,
  Hitman,
  BreakingBad,
  Got,
  Equalizer,
  Taken
};
