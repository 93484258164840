const BASE_URL = 'https://storage.googleapis.com/tijanisidiali/Videos/';

const DBZVideo = `${BASE_URL}dbz.mp4`;
const HXHVideo = `${BASE_URL}hxh.mp4`;
const KurokoVideo = `${BASE_URL}kuroko-no-basket.mp4`;
const SNKVideo = `${BASE_URL}snk.mp4`;

export {
  DBZVideo,
  HXHVideo,
  KurokoVideo,
  SNKVideo,
};
