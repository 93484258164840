/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { Language } from '@material-ui/icons';
import {
  
  
  
  BPM,
  Budget,
  Camera,
  CNRS,
  DeepLearning,
  IPparis,
  Profile,
  Somelec,
  Tetris,
  Todo,
  ENIT,
  ENSTA,
  Replay,
  SoundOff,
  SoundOn,
  Personal,
  IA,
  Database,
  Devop,
  Languges,
  Levi,
  ErenThumb,
  Kuruko,
  KurukoThumb,
  Goku,
  DragonBallThumb,
  Gon,
  GonThumb,
  LeagueOfLegend,
  WalkingDead,
  LifeIsStrange,
  WorldOfWarcraft,
  HotlineMiami,
  Python,
} from 'assets/img'
import { Equalizer, Taken, Got, Hitman, BreakingBad, RiseOfTheCobra } from 'assets/img/tvshows'
import {
  TwoOfUs,
  RickAstley,
  Dharma,
  Beautiful,
  DigitalLove,
  Everyday,
  NowOrNever,
  Rebenga,
  TakeOver
} from 'assets/sounds'

import { DBZVideo, HXHVideo, KurokoVideo, SNKVideo } from 'assets/videos';
import React from 'react';

export const getVideoUrl = (assetName: string): string => {
  return '/videos/' + assetName;
}

export const getSoundUrl = (assetName: string): string => {
  return '/sounds/' + assetName;
}


export interface Techno {
  frameworks: string[]
  bgColor: string
  icon: string
}

export interface TechnicalStackInterface {
  [key: string]: Techno
}

export const TechnicalStack: TechnicalStackInterface = {
  Python: {
    frameworks: ['Pandas', 'Streamlit', 'PyTorch', 'tensorflow', 'Kerras', 'YOLO'],
    bgColor: '#1E3264',
    icon: Python,
  },
  Languages: {
    frameworks: ['python', 'C', 'C++', 'C#', 'PHP', 'Dart'],
    bgColor: '#25ccb3',
    icon: Languges,
    
  },
  IA: {
    frameworks: ['Machine Learning', 'NLP', 'RNN', 'CNN'],
    bgColor: '#56b2d6',
    icon: IA,
    
  },
  DataBases: {
    frameworks: ['MySQL', 'PostgreSQL', 'Firestore', 'MongoDB'],
    bgColor: '#d1ca8a',
    icon: Database,
  },
  DevOps: {
    frameworks: ['HTML5', 'CSS', 'javascript', 'Typescript', 'React', 'Boostrap', 'Flutter'],
    bgColor: '#19912d',
    icon: Devop,
  },
}

export enum TechnicalStackKeys {
  Python = 1,
  Cmra = 2,
  Bpm = 3,
  Pizza = 4,
  profolio = 5,
  somlec = 6,
  Flutter = 7,
  Game = 8 ,
}

export const SongsInformations: PlaylistProps[] = [
  {
    title: 'Digital Love',
    artist: 'Daft Punk',
    src: DigitalLove
  },
  {
    title: 'Dharma',
    artist: 'KSHMR',
    src: Dharma
  },
  {
    title: 'Take Over',
    artist: 'League of Legends',
    src: TakeOver
  },
  {
    title: 'Beautiful',
    artist: 'Eminem',
    src: Beautiful
  },
  {
    title: 'Now Or Never',
    artist: 'Kendrick Lamar',
    src: NowOrNever
  },
  {
    title: 'Rebenga',
    artist: 'PNL',
    src: Rebenga
  },
  {
    title: 'Every day',
    artist: 'Ninho',
    src: Everyday
  },
  {
    title: 'Never Gonna Give You Up',
    artist: 'Rick Astley',
    src: RickAstley
  },
  {
    title: 'Grover Washington',
    artist: 'Just The Two of Us',
    src: TwoOfUs
  },
]

export const experiences: ModalPropsType[] = [
  {
    title: 'M1 second-year intern',
    bgColor: '#596acf',
    stack: 'Python | Streamlit | Image Processing',
    date: 'Jun 2024 - Aug 2024',
    subtitle: 'CNRS',
    source: CNRS,
    location: 'Paris - France',
    description: `⇨ 𝑴𝒀 𝑴𝑰𝑺𝑺𝑰𝑶𝑵: Developing a web platform that integrates AI algorithms for the early detection of glaucoma. The key features of this platform include:

    ➤ Acquisition and preprocessing of retinal images, enabling the platform to generate automated predictions regarding the presence of glaucoma.
    ➤ User management for both patients and doctors, allowing seamless interaction between the two parties.
    ➤ Medical consultation management, providing doctors with easy access to patient information and the ability to review diagnostic results.
    ➤ An intuitive user interface designed to simplify navigation and enhance the user experience for both medical professionals and patients.

  𝐁𝐚𝐜𝐤𝐄𝐧𝐝: Python
  𝐃𝐚𝐭𝐚𝐛𝐚𝐬𝐞: MongoDB
  𝐅𝐫𝐨𝐧𝐭𝐄𝐧𝐝: HTML, CSS, Javascript, Node.js
  `,
    mainTechno: [TechnicalStackKeys.Python, TechnicalStackKeys.Cmra, TechnicalStackKeys.Bpm, TechnicalStackKeys.Game]
  },
  {
    title: 'Freelancer',
    bgColor: '#17a30a',
    stack: 'C++ | SQLite | finacial reporting',
    subtitle: 'Banque Populaire De Mauritanie',
    date: 'Jan 2024 - Apr 2024',
    source: BPM,
    location: 'remote internship',
    description: `⇨ 𝑴𝒀 𝑴𝑰𝑺𝑺𝑰𝑶𝑵: developing a personal expense management module integrated into their mobile payment platform. The module includes the following features:

      ➤ Transaction tracking, allowing users to monitor their spending and income in real-time.
      ➤ Budget setting capabilities, helping users plan and control their expenses more effectively
      ➤ Detailed financial reporting, enabling users to generate comprehensive reports on their financial activities.
  `,
    mainTechno: [TechnicalStackKeys.Bpm]
  },
  {
    title: 'First-year engineering intern operator',
    bgColor: '#6196ab',
    subtitle: 'Somelec',
    date: 'Jul 2023 - Aug 2023',
    stack: 'teamwork | MATLAB | Exceel',
    source: Somelec,
    location: 'Nouakchott - Mauritanie',
    description: `⇨ 𝐌𝐘 𝐌𝐈𝐒𝐒𝐈𝐎𝐍:  during this operator internship i had the opportinuty to observe the work of software engineers at SOMELEC, understand how they manage the data on their platform, and ensure the connection between the various stakeholders.
`,
    mainTechno: [TechnicalStackKeys.Python, TechnicalStackKeys.Cmra, TechnicalStackKeys.Game]
  },
  {
    title: 'Team-based engineering project',
    bgColor: '#0c02c7',
    subtitle: 'ENSTA Paris',
    date: 'oct 2023 - May 2024',
    stack: 'YOLO |  Raspberry Pi | OpenCv',
    source: ENSTA,
    location: 'ENSTA Paris',
    description: `⇨ 𝐌𝐘 𝐌𝐈𝐒𝐒𝐈𝐎𝐍:  Development of athlete-tracking and line-tracking algorithms, as well as their integration with the electronic components to ensure the proper functioning of the robot.

    𝐀𝐛𝐨𝐮𝐭 𝐭𝐡𝐞 𝐩𝐫𝐨𝐣𝐞𝐜𝐭:
    The project is a Tracking Camera for Athletics. In athletics, video imaging is increasingly used by coaches to tailor training sessions to the specific needs of each runner. Currently, footage is typically captured using a fixed or handheld camera, which limits the analysis to a specific portion of the race and may lack precision. The ideal solution would be to continuously film the athlete, similar to the cameras used in the Olympic Games. This project aims to design a robot capable of autonomously following and filming an athlete. It involves designing and integrating key hardware components such as a mobile base (miniature car model), a lane-following camera, and an athlete-tracking camera.

`,
    mainTechno: [TechnicalStackKeys.Python, TechnicalStackKeys.Cmra, TechnicalStackKeys.Game]
  },
  {
    title: 'school project',
    bgColor: '#c96b8d',
    subtitle: 'Object-Oriented Programming course',
    date: 'Oct 2023 - Mar 2024',
    stack: 'C++ | 3D Programming | GLFW | OpenGL',
    source: Tetris,
    location: 'ENSTA Paris',
    description: `⇨ 𝐌𝐘 𝐌𝐈𝐒𝐒𝐈𝐎𝐍:   Developing a 3D Tetris game using C++, similar to BLOCKOUT

    𝐀𝐛𝐨𝐮𝐭 𝐭𝐡𝐞 𝐩𝐫𝐨𝐣𝐞𝐜𝐭:
    The 3D Tetris game, inspired by BLOCKOUT, uses OpenGL and GLFW for rendering and input handling. Players control and rotate tetrominoes within a 3D grid, aiming to fill entire layers without gaps. The game features a continuous loop where pieces are drawn and controlled using keyboard inputs, with movement and rotation managed by custom algorithms. C++ is used for the core development, along with grid management and 3D object rendering. The project integrates sound through musicConsole for background music or sound effects.

    𝐂++ , 𝐆𝐋𝐅𝐖 , 𝐎𝐩𝐞𝐧𝐆𝐋 , 𝐦𝐮𝐬𝐢𝐜𝐂𝐨𝐧𝐬𝐨𝐥𝐞

`,
    mainTechno: [TechnicalStackKeys.Python, TechnicalStackKeys.Cmra, TechnicalStackKeys.Game]
  },
  {
    title: 'School project',
    bgColor: '#6b6514',
    subtitle: 'Mobile development project',
    date: 'jan 2023 - mai 2023',
    stack: 'Flutter | fireStore | SQLite',
    source: Todo,
    location: 'ENIT',
    description: `⇨ 𝐌𝐘 𝐌𝐈𝐒𝐒𝐈𝐎𝐍:  Develop a Flutter-based ToDo application for task management with features such as adding, editing, deleting, and viewing tasks. The project involves managing tasks both locally and in the cloud, implementing user authentication, and providing a polished user interface with notifications and multi-language support.

    𝐀𝐛𝐨𝐮𝐭 𝐭𝐡𝐞 𝐩𝐫𝐨𝐣𝐞𝐜𝐭:
    The ToDo application aims to help users efficiently manage their task lists with a focus on user-friendly design and functionality. The core features include task creation, editing, deletion, and categorization by priority or category, with tasks stored either locally in an SQLite database or remotely in a cloud-based database like Firebase Firestore. Additionally, the app includes login functionality using Firebase, task scheduling with date and time, and notifications for due tasks. The interface is designed with animations and supports internationalization for multiple languages such as French and English. For state management, either the Provider or Riverpod method will be utilized


`,
    mainTechno: [TechnicalStackKeys.Python, TechnicalStackKeys.Cmra, TechnicalStackKeys.Game]
  },
  {
    title: 'Personal website',
    bgColor: '#25a2cc',
    subtitle: 'React project',
    date: 'jul 2024',
    stack: 'React | Typescript | Boostrap',
    source: Personal,
    location: 'Paris - France',
    description: `⇨ I developed a website using TypeScript, React, and Bootstrap that showcases my work as a developer and designer. The site is designed to be responsive and user-friendly, incorporating interactive elements such as videos, animations, and media content to engage users. It highlights my skills in both frontend and backend technologies, providing an in-depth look at my projects, professional achievements, and technical expertise. The portfolio serves as a comprehensive platform for displaying my creative and technical journey in web development.


`,
    mainTechno: [TechnicalStackKeys.Python, TechnicalStackKeys.Cmra, TechnicalStackKeys.Game]
  },
  
  
]

export const schools = [
  {
    title: 'Master of Science in Engineering with a specialization in AI at ENSTA Paris',
    subtitle: 'ENSTA Paris',
    description: `ENSTA Paris, founded in 1741, is a multidisciplinary engineering school offering an excellent education and holding a prominent place in French higher education and research. As part of the Institut Polytechnique de Paris, ENSTA Paris benefits from collaboration with renowned institutions such as CNRS, INRIA, and École Polytechnique, with research conducted by both its faculty and external researchers. This integration allows the school to provide a well-rounded education and research opportunities, establishing itself as a leader in the field both nationally and internationally.
    
    ➤ Databases (SQL, PL/SQL, NoSQL, Transactions, ...)
    ➤ Estimation and Statistical Identification (Bayesian Estimation, Kalman Filter, ...)
    ➤  Machine Learning (Bayesian Model, K-NN, Decision Trees, Boosting, Bagging, Random Forests, SVM, Unsupervised Learning, K-means, Clustering, Deep Learning, ReLU Activation Function, ...)
    ➤ Software Engineering and Object-Oriented Programming (C++ course covering classes, templates, threads, concepts, ...)
    ➤ Information Theory (Reed-Solomon Code, Galois Theory, ...)
    ➤ The Functioning of Networks (Cisco Packet Tracer, Wireshark, ...)`,
    source: ENSTA,
    location: 'Paris - France',
    date: '2023 - 2025',
    bgColor: '#5f6e9c'
  },
  {
    title: 'Master of Science in Engineering with a specialization in Computer Science at ENIT Tunis',
    subtitle: 'ENIT',
    location: 'Tunis - Tunisie',
    date: '2022 - 2025',
    description: `ENIT, the oldest engineering school in Tunisia, plays a central role in the country’s scientific and economic development, training around 1,500 engineering students and nearly 1,200 master’s and doctoral students each year. With over 20 research laboratories and units, ENIT boasts strong infrastructure and recognized scientific expertise in various fields of engineering sciences and technology. In collaboration with ENSTA Paris, ENIT offers a joint three-year program combining high-level multidisciplinary engineering education in Tunisia and France, leading to dual engineering degrees from both ENIT and ENSTA Paris.

    ➤ Engineering Mathematics and Numerical Analysis.
    ➤ Algorithms and Programming (in Python & C).
    ➤ Operations Research (Linear Programming, Simplex Method, Duality, Graph Theory...).
    ➤ Multi-platform Mobile Development (Flutter/Dart, Firebase...).
    ➤ Web Development (React, Bootstrap, Node.js, MongoDB...)
    ➤ Microprocessor Architecture (VHDL, Assembly Language, Memory, Cache, Simplescalar, gem5...).`,
    source: ENIT,
    bgColor: '#85bcd6'
  },
  {
    title: 'Currently pursuing a Master of Science in Data and AI at IP Paris',
    subtitle: 'IP Paris',
    description: `The Institut Polytechnique de Paris (IP Paris) is a leading institution of science and technology, bringing together six prestigious French engineering schools: École Polytechnique, ENSTA Paris, ENPC, ENSAE Paris, Télécom Paris, and Télécom SudParis. With a focus on providing top-tier education and pioneering research, IP Paris leverages its schools' centuries of expertise to make a global impact, producing Nobel laureates and prominent figures in various fields.
    `,
    source: IPparis,
    location: 'Paris - France',
    date: '2024 - 2025',
    bgColor: '#549096'
  },
  
]

export const Mangas = [
  {
    src: getVideoUrl(SNKVideo),
    icon: Levi,
    thumb: ErenThumb,
    title: 'Attack on Titans',
  },
  {
    src: getVideoUrl(KurokoVideo),
    icon: Kuruko,
    thumb: KurukoThumb,
    title: 'Kuroko\'s Basket',
  },
  {
    src: getVideoUrl(DBZVideo),
    icon: Goku,
    thumb: DragonBallThumb,
    title: 'Dragon Ball Z',
  },
  {
    src: getVideoUrl(HXHVideo),
    icon: GonThumb,
    thumb: Gon,
    title: 'Hunter x Hunter',
  }
]

export const VideoGames: VideoGameType[] = [
  {
    title: 'League of legends',
    img: LeagueOfLegend,
    type: 'Favorite Game'
  },
  {
    title: 'Life Is Strange',
    img: LifeIsStrange,
    type: 'Best Story'
  },
  {
    title: 'The Walking Dead A New Frontier',
    img: WalkingDead,
    type: 'Favorite Art Style'
  },
  {
    title: 'World of Warcraft',
    img: WorldOfWarcraft,
    type: 'Best Soundtrack'
  },
  {
    title: 'Hotline Miami',
    img: HotlineMiami,
    type: 'Hardest Game'
  },
]

export interface VideoGameType {
  title: string
  img: string
  type: string
}

export interface ModalPropsType {
  title: string
  stack?: string
  bgColor: string
  date: string
  subtitle: string
  mainTechno?: TechnicalStackKeys[]
  source: string
  location: string
  description: string
}

export const TVShowsData = [
  {
    name: 'Rise of the cobra',
    image: RiseOfTheCobra
  },
  {
    name: 'Hitman',
    image: Hitman
  },
  {
    name: 'Breaking Bad',
    image: BreakingBad
  },
  {
    name: 'Game of thrones',
    image: Got
  },
  {
    name: 'The Equalizer',
    image: Equalizer
  },
  {
    name: 'Taken',
    image: Taken
  },
]

export interface TVShowInterface {
  name: string
  image: string
}
export interface PlaylistProps {
  title: string
  artist: string
  src: string
}