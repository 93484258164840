const BASE_URL = 'https://storage.googleapis.com/tijanisidiali/assets/sounds/';

const TwoOfUs = `${BASE_URL}just-the-two-of-us.mp3`;
const RickAstley = `${BASE_URL}rick-astley.mp3`;
const Dharma = `${BASE_URL}dharma.mp3`;
const Beautiful = `${BASE_URL}beautiful.mp3`;
const DigitalLove = `${BASE_URL}digital-love.mp3`;
const Everyday = `${BASE_URL}everyday.mp3`;
const NowOrNever = `${BASE_URL}now-or-never.mp3`;
const Rebenga = `${BASE_URL}rebenga.mp3`;
const TakeOver = `${BASE_URL}take-over.mp3`;

export {
  TwoOfUs,
  RickAstley,
  Dharma,
  Beautiful,
  DigitalLove,
  Everyday,
  NowOrNever,
  Rebenga,
  TakeOver
};
